<template>
  <Layout>
    <CertificatesComponent />
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout.vue";
import CertificatesComponent from "@/components/CertificatesComponent.vue";

export default {
  components: {
    Layout,
    CertificatesComponent,
  },
};
</script>
