<template>
  <div class="certificate">
    <div class="certificate-title">
      <PageTitle :title="$t('certificates_component.certificados')">
        <IconSax name="medal-star" type="outline" />
      </PageTitle>

      <div v-if="loadCertificates && certificates.length > 0">
        <PageSubtitle :subtitle="$t('certificates_component.parabens')" />
      </div>
      <div v-else-if="loadCertificates">
        <PageSubtitle :subtitle="$t('certificates_component.vazio')" />
        <PageSubtitle :subtitle="$t('certificates_component.advance')" />
      </div>
    </div>
    <div
      class="certificate-wrap"
      v-if="!isLoading && certificates.length > 0"
      data-anima="top"
    >
      <CertificateCard
        v-for="(certificate, $index) in certificates"
        :certificate="certificate"
        :key="$index"
        @view="viewCertificate(certificate.id)"
        @download="
          downloadAndSaveCertificate(certificate.id, certificate.title)
        "
      />
    </div>
    <infinite-loading @infinite="infiniteHandler" v-if="!isLoading">
      <div slot="no-more">
        <p class="body-regular-14 color-base"></p>
      </div>
      <div slot="no-results">
        <p class="body-regular-14 color-base"></p>
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

import CertifyService from "@/services/resources/CertifyService";
const certificateService = CertifyService.build();

import CertificateCard from "@/components/pages/certificates/CardCertificates.vue";
import PageTitle from "@/components/common/TitlePage.vue";
import PageSubtitle from "@/components/common/SubtitlePage.vue";
import { sanitize } from "@/utils/strings";

export default {
  components: {
    PageTitle,
    PageSubtitle,
    CertificateCard,
  },
  data() {
    return {
      loadCertificates: false,
      isLoading: false,
      certificates: [],
      page: 1,
      total: null,
      lastPage: 1,
      totalCertificates: 0,
      selectedCertify: {
        id: 24,
        site_id: 1,
        course_id: 24,
        media_id: 5,
        created_by: 1,
        updated_by: 1,
        deleted_by: null,
        created_at: "2021-03-05T18:41:31.000000Z",
        updated_at: "2021-03-05T18:41:31.000000Z",
        media: {
          id: 5,
          site_id: 1,
          title: "pexels-karolina-grabowska-4498362.jpg",
          path: "media/1/pexels-karolina-grabowska-4498362.jpg",
          mime: "image/jpeg",
          storageAdapter: "Storage",
          disk: "do_space",
          protected: 0,
          connection_id: null,
          created_by: 1,
          updated_by: 1,
          created_at: "2021-03-05T18:41:25.000000Z",
          updated_at: "2021-03-05T18:41:25.000000Z",
          cdn_url:
            "https://nyc3.digitaloceanspaces.com/club.greenn/media/1/pexels-karolina-grabowska-4498362.jpg",
          media_meta: [],
        },
      },
    };
  },
  methods: {
    infiniteHandler($state) {
      if (this.lastPage > this.page) {
        $state.complete();
        return;
      }

      this.loadCertificates = false;

      certificateService
        .read("?page=" + this.page)
        .then((response) => {
          this.lastPage = response.last_page;
          this.total = response.total;

          if (this.certificates.length < response.total) {
            this.page += 1;
            this.certificates.push(...response.data);
            $state.loaded();
            this.loadCertificates = true;
          } else {
            $state.complete();
            this.loadCertificates = true;
          }
        })
        .catch((error) => {
          console.error(error);
          $state.error();
        });
    },

    async downloadCertificate(id) {
      const response = await axios({
        url: process.env.VUE_APP_API_HOST + `/certificate/download/${id}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: Cookies.get("auth_greennCourse"),
        },
      });
      return response;
    },

    createDownloadLink(response, target = "_self") {
      const type = response.headers["content-type"];
      var fileURL = window.URL.createObjectURL(
        new Blob([response.data], { type })
      );

      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("target", target);
      document.body.appendChild(fileLink);
      fileLink.click();
    },

    async viewCertificate(id) {
      const response = await this.downloadCertificate(id);
      this.createDownloadLink(response, "_blank");
    },

    async downloadAndSaveCertificate(id, title) {
      const response = await this.downloadCertificate(id);
      const type = response.headers["content-type"];
      var fileURL = window.URL.createObjectURL(
        new Blob([response.data], { type })
      );

      let certificateNamePrefix = this.$t('modal_new_certificate.certificate_name_prefix');
      let sanitizedCourseName = certificateNamePrefix + '-' + sanitize(title)

      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", sanitizedCourseName);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.certificate {
  padding: 64px 40px 100px 40px;

  @media screen and (max-width: 768px) {
    padding: 32px 16px;
  }

  &-title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }

  &-wrap {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
}
</style>
