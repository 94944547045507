<template>
  <div v-if="showCertificate(certificate)" class="certificate-card">
    <div
      @click="$emit('view')"
      class="certificate-image"
      :style="{
        'background-image': 'url(' + certificate.media.cdn_url + ')',
      }"
    ></div>

    <div class="certificate-content">
      <div class="certificate-achievements">
        <span class="certificate-achievements__icons">
          <IconSax name="medal-star" type="bold" />
        </span>
        <span class="certificate-achievements__date"
          >{{ $t("certificates_component.conquista") }}
          {{ certificate.created_at | moment("DD/MM/YYYY") }}</span
        >
      </div>
      <div class="certificate-title">
        {{ truncateText(certificate.course.title, 48) }}
      </div>
      <div class="certificate-toolbar">
        <div class="certificate-toolbar__download" @click="$emit('download')">
          <span
            :id="`download-target-${certificate.id}`"
            class="certificate-toolbar__download--name"
            title="Clique para fazer o download do certificado"
          >
          {{ $t("certificates_component.download") }}
          </span>
          <b-tooltip
            :target="`download-target-${certificate.id}`"
            triggers="hover"
          >
          {{ $t("certificates_component.click") }}
          </b-tooltip>
          <span class="certificate-toolbar__download--svg">
            <IconSax name="document-download" type="outline" />
          </span>
        </div>
        <div class="certificate-toolbar__share" title="Compartilhe">
          <ShareButton :recentes="certificate" @share="share" />
        </div>
      </div>
    </div>
  </div>
  <div class="certificate-opacity" v-else>
    <div
      class="certificate-opacity-image"
      :style="{
        'background-image': 'url(' + certificate.media.cdn_url + ')',
      }"
    ></div>
  </div>
</template>

<script>
import ShareButton from "@/components/pages/certificates/ShareButton.vue";
import { mapGetters } from "vuex";

export default {
  emit: ["download", "view"],
  props: {
    certificate: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getMe",
      isMobile: "layout/getIsMobile",
    }),
  },
  methods: {
    scrollToDown() {
      let scrollAmount = 50;
      window.scrollBy(0, scrollAmount);
    },
    showCertificate(certificate){
      if(certificate.certificate_extension_config){
        return certificate.certificate_extension_config.auto_issuance || certificate.status === "emited";
      }else{
        return true
      }
    },
    truncateText(text, limit) {
      return text.length <= limit ? text : text.slice(0, limit) + "...";
    },

    async shareOnLinkedIn() {
      const {
        title: certificateTitle,
        url: certificateUrl,
        created_at: createdAt,
      } = this.certificate;

      if (!certificateUrl) {
        console.error("certificateUrl is undefined");
        return;
      }

      const certificateDescription = "Exemplo de descrição do certificado";

      const certificateIdMatch = certificateUrl.match(
        /certificates\/([0-9a-f-]+)\.pdf/
      );

      if (!certificateIdMatch) {
        console.error("No match found in certificateUrl");
        return;
      }

      const certificateId = certificateIdMatch[1];
      const creationDate = new Date(createdAt);
      const issueYear = creationDate.getFullYear();
      const issueMonth = creationDate.getMonth() + 1;
      const organizationName = this.currentUser.current.currentSite.name;

      const params = new URLSearchParams({
        startTask: "CERTIFICATION_NAME",
        name: certificateTitle,
        description: certificateDescription,
        organizationName,
        issueYear,
        issueMonth,
        certId: certificateUrl,
        certUrl: certificateId,
      });
      const linkedInProfile = "https://www.linkedin.com/profile/add?";
      const linkedInUrl = linkedInProfile + params.toString();

      window.open(linkedInUrl, "_blank");
    },

    share(item) {
      const { network, id } = item;
      if (network === "linkedin") {
        this.shareOnLinkedIn(id);
      } else if (network === "whatsapp") {
        this.shareOnWhatsApp(id);
      } else if (network === "email") {
        this.shareByEmail(id);
      }
    },

    shareOnWhatsApp() {
      const certificateUrl = this.certificate.url;
      const sharedText = `Olá, desejo compartilhar meu certificado: ${certificateUrl}`;
      const encodedText = encodeURIComponent(sharedText);
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedText}`;
      window.open(whatsappUrl);
    },

    shareByEmail() {
      const certificateUrl = this.certificate.url;
      const sharedText = `Olá, desejo compartilhar meu certificado: ${certificateUrl}`;
      const encodedText = encodeURIComponent(sharedText);
      const emailUrl = `mailto:?subject=Certificado&body=${encodedText}`;
      window.open(emailUrl);
    },
  },
  components: {
    ShareButton,
  },
};
</script>

<style lang="scss" scoped>
.certificate {
  &-wrap {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  &-card {
    width: 262px;
    height: 310px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    box-shadow: var(--shadow-b);
    border-radius: 8px 8px 8px 8px;
    background: var(--certificates-background);

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 390px;
    }
  }

  &-opacity {
    width: 262px;
    opacity: 0.7;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    box-shadow: var(--shadow-b);
    border-radius: 8px 8px 8px 8px;
    background: var(--certificates-background);

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 390px;
    }

    &-image {
      width: 100%;
      height: 175px;
      background: #fff;
      border-radius: 8px 8px 0px 0px;
      background-size: cover;

      @media screen and (max-width: 768px) {
        height: 260px;
      }
    }
  }

  &-image {
    cursor: zoom-in;
    width: 100%;
    height: 175px;
    background: #fff;
    border-radius: 8px 8px 0px 0px;
    background-size: cover;

    @media screen and (max-width: 768px) {
      height: 260px;
    }
  }

  &-content {
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    color: #81858e;
    padding: 16px 16px;
    gap: 8px;
    border-radius: 0px 0px 8px 8px;
  }

  &-achievements {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    @extend .body-semibold-12;

    &__icons {
      ::v-deep .svgCss {
        svg {
          width: 16px;
          height: 16px;
        }

        svg path {
          fill: var(--maincolor);
        }
      }
    }
  }

  &-title {
    color: var(--certificates-title);
    @extend .body-semibold-16;
    height: 40px;
  }

  &-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    width: 100%;

    &__download {
      cursor: pointer;
      color: var(--maincolor);
      display: flex;
      @extend .body-regular-14;
      gap: 8px;

      &:hover {
        opacity: 0.8;
      }

      &--svg {
        display: flex;
        align-items: center;
      }

      ::v-deep .svgCss {
        svg {
          width: 14px;
          height: 14px;
        }

        svg path {
          fill: var(--maincolor);
        }
      }
    }

    &__share {
    }
  }
}
</style>
