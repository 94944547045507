<template>
  <div class="commomSubtitlePage">
    {{ subtitle }}
  </div>
</template>

<script>
export default {
  props: {
    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.commomSubtitlePage {
  color: var(--opposite-color);
  cursor: default;
  font: var(--body-regular-16);
  color: var(--subtitle-color);
  flex-basis: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

  ::v-deep svg path {
    fill: var(--opposite-color);
  }
}
</style>
