import { render, staticRenderFns } from "./CardCertificates.vue?vue&type=template&id=7477ee4f&scoped=true&"
import script from "./CardCertificates.vue?vue&type=script&lang=js&"
export * from "./CardCertificates.vue?vue&type=script&lang=js&"
import style0 from "./CardCertificates.vue?vue&type=style&index=0&id=7477ee4f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7477ee4f",
  null
  
)

export default component.exports